import { CustomFieldOption } from "@/api/interface/custom-field"
import { CustomField } from "@/api/interface/custom-field"
import { AxiosError } from "axios"
import errorMessages from "@/consts/ErrorMessages"
import dayjs from "dayjs"
import { ObjWithCreatedAt } from "@/api/interface/utils"

export const getSelectedObjectById = <T extends { id: string }>(
  id: string,
  objects: Array<T>
) => objects?.find((o) => o.id === id)

export const secToMin = (seconds: number) => seconds / 60

export const minToSec = (minutes: number) => minutes * 60

export const secToHour = (seconds: number) => !seconds ? 0 : seconds / 3600

export const hourToSec = (hours: number) => !hours ? 0 : hours * 3600

export const getTimeFormat = (minutes: number, seconds?: number) => {
  let str = "0m"

  //* only display seconds if no minutes left
  if (seconds) {
    str = `${Math.round(seconds)}s`
  }
  if (minutes) {
    str = `${Math.round(minutes)}m`
  }

  return str
}

export const addDashToNumber = (str: string, position: number) => {
  const arrayOutput = [str.slice(0, position), "-", str.slice(position)]

  return arrayOutput.join("")
}

export const formatPhoneNumber = (text: string | number) => {
  let value = text?.toString().replace(/^(\+?1)|[^\d]/g, "") || ""

  if (value.length > 10) value = value.substring(0, 10)

  if (value.length > 6) value = addDashToNumber(value, 6)
  if (value.length > 3) value = addDashToNumber(value, 3)

  return value
}

interface SvgToPngOptions {
  filename: string
  height?: number
  svgNodeId: string
  width: number
}

export const svgToPng = ({
  filename,
  height,
  svgNodeId,
  width,
}: SvgToPngOptions) => {
  const c = document.createElement("canvas")
  c.width = width
  c.height = height || width

  const svgNode = document.getElementById(svgNodeId)
  const data = new XMLSerializer().serializeToString(svgNode)
  const blob = new Blob([data], { type: "image/svg+xml" })

  const img = new Image(c.width, c.height)
  img.src = window.URL.createObjectURL(blob)
  img.onload = function () {
    c.getContext("2d").drawImage(img, 0, 0, c.width, c.height)
    const uri = c.toDataURL()
    const a = document.createElement("a")
    a.href = uri
    a.download = `${filename}.png`
    a.click()

    window.URL.revokeObjectURL(uri)
  }
  img.remove()
  c.remove()
}

export const getDefaultCustomFieldValue = (type: CustomField["type"], options?: CustomFieldOption[], value?: string, guestQueueId?: string) => {
  if (
    type === "string" ||
    type === "tel" ||
    type === "email" ||
    type === "text" ||
    type === "number" ||
    type === "select" ||
    type === "radio" 
  ) {
    return ""
  }

  if (type === 'date') {
    return ''
  }

  if (type === 'time') {
    return ''
  }

  if (type === 'queueselect') {
    
    if (guestQueueId) { 
      return options?.find(option => option.valueMeta === guestQueueId)?.value || options?.[0]?.value
    }
    else {
      return options?.[0]?.value
    }
    
  }

  return []
}

export const printAxiosErrors = (
  err: AxiosError<{ errors: Array<any>; errId: string }>,
  isUserFriendly = false
) => {
  const { errors, errId } = err?.response?.data || {}

  if (err?.response?.status === 419) {
    return "Broken login detected. Please log out and try again."
  }

  if (!errors)
    return "Something went wrong. Please try again and contact support if the issue persists."
  console.log(errors)

  if (!isUserFriendly) {
    return (
      `_Error Messages_\nError Id: ${errId}\n` +
      Object.entries(errors)
        .map(([key, value], index) => `(${index + 1}) ${key}: ${value}`)
        .join("\n")
    )
  }

  return (
    Object.entries(errors)
      .map(([key, value]) => `${key}: ${mapErrorMessages(value)}`)
      .join("\n")
  )
  
}

const mapErrorMessages = (message) => {
  if (errorMessages[message]) {
    return errorMessages[message]
  }

  return message
}

//TODO timezone calculation
//* Expect time string like "09:00"
export const convertTimeTo24H = (time: string, meridiem?: "AM" | "PM") => {
  const minute = time?.split(":")[1] || "00"

  const parsedHr = parseInt(time?.split(":")[0] || "00", 10)
  const hour = parsedHr + (meridiem === "PM" && parsedHr < 12 ? 12 : 0)

  return `${hour.toString().length === 2 ? hour : "0" + hour}:${minute}`
}

export const getMeridiemFromTime = (time: string) =>
  parseInt(time?.split(":")?.[0], 10) < 12 ? "AM" : "PM"

export const generateRandomString = (length: number) => {
  let result = ""
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%&"

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }

  return result
}

export const enforceGuid = (str: string) =>
  str.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/)?.[0]

//* old but it was a bitch to write so I'm not deleting it yet 😅
/*function suggestQueue(partySize) {
  if (!(partySize > 0)) {
    return queueselectOptions[0]?.id
  }

  const ps = parseInt(partySize, 10)

  const sizedQueues = queueselectOptions
    .map((x) => {
      //* matches "3-6 Tops" or "8+ Tops" but not "table for 2" or "bob's 20th birthday"
      const a = x.label.match(/^(\d).?(\d?)/) || []

      if (a[1] && a[2]) {
        return { id: x.id, size: range(parseInt(a[1]), parseInt(a[2])) }
      }
      if (a[1] && !a[2]) {
        return { id: x.id, size: parseInt(a[1]) }
      }
      return null
    })
    .filter((x) => !!x)

  const queue = sizedQueues.find((x) =>
    Array.isArray(x.size) ? x.size.includes(ps) : ps >= x.size
  )

  return queue ? queue.id : queueselectOptions[0]?.id
}*/

export const titleCase = (str: string) => {
  const strArr = str.split(' ')

  return strArr.map(item => {
    return item.replace(item[0], item[0].toUpperCase())
  }).join(' ')
}

export const getCounterSuffix = (number: number) => {
  switch (number) {
    case 1:
      return `${number}st`
    case 2:
      return `${number}nd`
    case 3:
      return `${number}rd`
    default:
      return `${number}th`
  }
}

export const isDeepEqual = (a: any, b: any) => {
  let isEqual = Object.is(a, b)

  if (!isEqual) {
    if (Array.isArray(a) && Array.isArray(b)) {

      isEqual = (a.length === b.length) && a.every(
        (item, idx) => isDeepEqual(item, b[idx])
      )
    } else if (
      a && b
      && (typeof a === 'object')
      && (typeof b === 'object')
    ) {
      const aKeys = Object.keys(a);
      const bKeys = Object.keys(b);

      isEqual = (aKeys.length === bKeys.length) && aKeys.every(
        (key) => isDeepEqual(a[key], b[key])
      )
    }
  }
  return isEqual;
}

export const createdAtDescSortComparison = (
  a: ObjWithCreatedAt,
  b: ObjWithCreatedAt
) => {
  const aCreatedAt = dayjs(a.createdAt)
  const bCreatedAt = dayjs(b.createdAt)
  if (dayjs(aCreatedAt).isAfter(dayjs(bCreatedAt))) {
    return -1
  } else if (aCreatedAt.isBefore(bCreatedAt)) {
    return 1
  }
  return 0
}

export const dedupeArr = (arr: unknown[]) => {
  if (!Array.isArray(arr)) return

  return Array.from(new Set(arr.map((item) => JSON.stringify(item)))).map((item) => JSON.parse(item))
}